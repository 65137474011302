<template>
  <div class="language-menu">
    <a class="icon-button" @click="data.showMenu = true"><icon-language></icon-language>
      <div class="nobr">{{ selectedLanguage }}</div>
    </a>
    <speech-bubble v-model="data.showMenu">
      <div v-for="lang in data.langs" class="lang-item" @click="handleChangeLanguage(lang)"
        :class="{ sel: lang.Code === data.selected }" :key="lang.Code">
        {{ lang.Description }}
      </div>
    </speech-bubble>
  </div>
</template>
<script setup>
import SpeechBubble from './SpeechBubble.vue'
import IconLanguage from './icons/IconLanguage.vue'
import { post } from '../utils.js'
import { inject, computed, reactive, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const store = inject('store')
const route = useRoute()
const router = useRouter()
const webapi = inject('webapi')

const data = reactive({
  showMenu: false,
  langs: [],
  selected: 'en-us'
})

const selectedLanguage = computed(() => {
  if (data.langs.length < 1) {
    return ''
  }
  let res = data.langs.find(el => el.Code === data.selected)
  if (res) {
    return res.Description
  }
  return 'Language'
})

const storeLanguage = computed(() => {
  return store.language
})

async function loadLangs() {
  data.langs = await post('GetLanguages')
}

async function loadSelected() {
  data.selected = await webapi.get('api/ccw/language/current')
  store.setLocalized(data.selected)
}

async function loadData() {
  loadLangs()
  loadSelected()
}

async function handleChangeLanguage(lang) {
  if (lang.Code === data.selected) {
    return
  }

  if (route.query && route.query.lang) {
    const q = Object.assign({}, route.query)
    delete q.lang
    router.replace({ query: q })
  }
  try {
    console.log('about to change language', lang.Code)
    const res = await webapi.post(`api/ccw/language/${lang.Code}/change`)
    console.log('result', res)
    data.selected = lang.Code
    store.setLocalized(data.selected)
  }
  catch (err) {
    console.log('error changing language', err)
  }

  router.go()
}

watch(storeLanguage, () => {
  data.selectedLanguage = storeLanguage
})

loadData()

</script>
<style>
.language-menu {
  position: relative;
}

.language-menu .nobr {
  white-space: nowrap;
}

.lang-item {
  cursor: pointer;
  white-space: nowrap;
}

.lang-item:hover {
  color: var(--ccw-hilite);
}

.lang-item.sel {
  color: #666;
  cursor: default;
}
</style>