import axios from 'axios'
import router from "./router";
import { applyBBCode } from 'getty-lib';

const zingtreeEmbed = `<iframe width="100%" frameborder="0" class="zingframe" seamless src="https://zingtree.com/deploy/tree.php?z=embed&disable_scroll=1&tree_id=582523260&start_node=15&variables=ACQcode&values=#vid#"></iframe>`

const sectionCache = []

async function post(url, data) {
  const response = await axios.post('/services/Service.svc/' + url, data)
  if (response) {
    var item = response.data.d
    return item
  } else {
    throw new Error('Document is empty')
  }
}

function gtag() {
  if (window.location.hostname === 'contributors.gettyimages.com') {
    if (window.gtag) {
      console.log('gtag arguments are', arguments)
      window.gtag(arguments)
    }
  }

  /*
  if (window.dataLayer) {
    window.dataLayer.push(arguments)
  }
    */

}

async function get(url) {
  const response = await axios.post(url)
  return response.data
}

const ga_measurement_id = 'G-3FEB5JR1CH'

function linkifyItem(link) {
  if (link.href) {
    const href = link.href
    if (href) {
      if (isRouterLink(href)) {

        if (!link.target) {

          const murl = new URL(href)
          link.addEventListener('click', (evt) => {
            evt.preventDefault()
            let glink = murl.pathname
            if (murl.search) {
              glink += '?' + murl.search
            }
            router.push(glink)
          })
        }
      }

      else {
        const murl = new URL(link.href)
        link.addEventListener('click', () => {
          const obj = {
            'event_category': 'link',
            'event_label': murl.pathname
          }
          window.gtag('event', 'click', obj)
          console.log('did gtag', obj)
        })
      }
    }
  }
}

function isRouterLink(url) {
  if (url.startsWith('http')) {
    return false
  }
  return true
}


async function linkify(items) {
  for (const item of items) {
    manageLink(item)
  }
}

const processableLinks = [
  {
    source: '/helpcategory.aspx',
    target: '/help/category/',
    param: 'cat_id'
  },
  {
    source: '/searchresults.aspx',
    target: '/search?search=',
    param: 'tag'
  },
  {
    source: '/helparticle.aspx',
    target: '/help/article/',
    param: 'article_id'
  },
  {
    source: '/article.aspx',
    target: '/article/',
    param: 'article_id'
  },
  {
    source: '/helplist.aspx',
    target: '/search?mode=F&search=',
    param: 'tagsearch'
  }
]

function manageLink(link) {
  if (!link) {
    return
  }
  if (!link.href) {
    return
  }

  const href = link.href.toLowerCase()
  for (let item of processableLinks) {
    if (href.indexOf(item.source) >= 0) {
      processLink(link, item.target, item.param)
      return
    }
  }
  linkifyItem(link)
}

function processLink(link, path, queryItem) {
  const url = new URL(link)
  const sp = new URLSearchParams(url.search)
  let mpath = path + sp.get(queryItem)
  link.setAttribute('href', mpath)
  link.addEventListener('click', (evt) => {
    evt.preventDefault()
    router.push(mpath)
  })
}

function setPageTitle(val) {
  if (!val) {
    val = ''
  }
  if (val === 'undefined') {
    val = ''
  }
  if (val) {
    val = ' - ' + val
  }
  document.title = 'Getty Images Contributor Community' + val
}

function waitfor(len) {
  return new Promise((resolve, reject) => {
    try {
      setTimeout(() => {
        resolve()
      }, len)
    } catch (err) {
      reject(err)
    }
  })
}

function getNavigateUrl(article) {
  if (article.Url) {
    return getLinkArticleUrl(article.Url)
  }
  else return '/article/' + article.Id
}

function getLinkArticleUrl(url) {
  url = url.replace('https://contributors.gettyimages.com', '')
  let u2 = url.toLowerCase()
  if (u2.indexOf('article.aspx')) {
    let wh = u2.indexOf('article_id=')
    if (wh >= 0) {
      wh += 11
      let id = u2.substring(wh)
      if (id) {
        return '/article/' + id
      }
    }

  }
  return url
}

function mreplace(item, txt, replacement) {
  const regex = new RegExp(escapeRegExp(item), 'g')
  return txt.replace(regex, replacement)
}

function escapeRegExp(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

function stripItems(item, txt, qual) {
  const start = '[' + item + ']'
  const end = '[/' + item + ']'
  if (!qual) {
    const wh = txt.indexOf(start)
    if (wh >= 0) {
      const en = txt.indexOf(end)
      if (en) {
        txt = txt.slice(0, wh) + txt.slice(en + end.length)
      }
    }
  } else {
    txt = txt.replace(start, '')
    txt = txt.replace(end, '')
  }

  return txt
}

function getFromSectionCache(sectionId) {
  if (!sectionId) {
    throw new Error('sectionId is undefined')
  }
  let res = sectionCache.filter(el => el.sectionId === sectionId)
  if (res && res.length) {
    return res[0].data
  }
}

function addToSectionCache(sectionId, data) {
  if (!getFromSectionCache(sectionId)) {
    sectionCache.push({
      sectionId: sectionId,
      data: data,
      modified: new Date()
    })
  }
}

const replacers = {
  name: '[NAME]',
  rl: '[RL]',
  rc: '[RC]',
  rx: '[RX]',
  rf: '[RF]',
  qly: '[qly]',
  qln: '[qln]',
  bp: '[BP]',
  np: '[NP]',
  urly: '[urly]',
  urln: '[urln]',
  url: '[URL]',
  vid: '[VID]',
  fl0: '[FL0]',
  fl1: '[FL1]',
  fl2: '[FL2]',
  fl3: '[FL3]',
  cl0: '[CL0]',
  cl1p: '[CL1+]',
  ex: '[EX]',
  lru: '[LRU]',
  zingtree: '[ZINGTREE-IFRAME]',
  dp: '[DP]' // CcTotalDownloads
}

const rps = []
for (const item in replacers) {
  rps.push(replacers[item])
}

function replaceItems(txt, member) {
  if (!txt) {
    return ''
  }
  if (rps.some(el => txt.includes(el))) {
    if (member) {
      const npoints = member.CreativeSubmissions + member.CustomSubmissions + member.CcTotalDownloads
      const plat = (member.TierDesc == 'Platinum')
      const hasurl = !!member.ReportUrl
      txt = stripItems('qly', txt, member.FutureLevel > 0)
      txt = stripItems('qln', txt, member.FutureLevel < 1)
      txt = stripItems('qln', txt, (member.FutureLevel < 1) && (!plat))
      txt = stripItems('qlp', txt, (member.FutureLevel < 1) && (plat))
      txt = stripItems('urly', txt, hasurl)
      txt = stripItems('urln', txt, !hasurl)
      txt = stripItems('FL0', txt, member.FutureLevel === 0)
      txt = stripItems('FL1', txt, member.FutureLevel === 1)
      txt = stripItems('FL2', txt, member.FutureLevel === 2)
      txt = stripItems('FL3', txt, member.FutureLevel === 3)
      txt = stripItems('CL0', txt, member.RewardLevel === 0)
      txt = stripItems('CL1+', txt, member.RewardLevel > 0)

      txt = mreplace(replacers.name, txt, member.FirstName)
      txt = mreplace(replacers.rl, txt, member.RewardLevel.toString())
      txt = mreplace(replacers.rc, txt, member.CreativeSubmissions.toString())
      txt = mreplace(replacers.rx, txt, member.CustomSubmissions.toString())
      txt = mreplace(replacers.rf, txt, member.FutureLevel.toString())

      txt = mreplace(replacers.dp, txt, member.CcTotalDownloads.toString())
      txt = mreplace(replacers.np, txt, npoints.toString())
      if (hasurl) {
        txt = mreplace(replacers.url, txt, member.ReportUrl)
      }
      txt = mreplace(replacers.vid, txt, member.VendorId)
      if (txt.includes(replacers.lru)) {
        txt = mreplace(replacers.lru, txt, member.LastRewardsUpdate)
      }
      if (txt.includes(replacers.zingtree)) {
        const regex = new RegExp(escapeRegExp('#vid#'), 'g')
        const val = zingtreeEmbed.replace(regex, member.VendorId)
        txt = mreplace(replacers.zingtree, txt, val)
      }
    }
  }
  console.log('doing applyBBCode')
  txt = applyBBCode(txt)
  return txt
}
const zingtreeTag = replacers.zingtree

function splitLines(txt) {
  const lines = txt.split(/\r\n/g)
  lines.map(el => el.trim())
  return lines.filter(el => el.length > 0)
}

function isExternalUrl(url) {
  if (url.startsWith('https')) {
    if (!url.includes('contributors.gettyimages.com') && !url.includes('contributors-beta.')) {
      return true
    }
  }
  return false
}

function goSignIn() {
  const path = window.location.pathname
  const item = encodeURIComponent('/ccw' + path)
  window.location = 'https://esp.gettyimages.com/sign-in?returnUrl=' + item;
}


export {
  post,
  get,
  gtag,
  ga_measurement_id,
  linkify,
  setPageTitle,
  waitfor,
  getNavigateUrl,
  replaceItems,
  zingtreeTag,
  getFromSectionCache,
  addToSectionCache,
  isRouterLink,
  splitLines,
  processLink,
  manageLink,
  isExternalUrl,
  goSignIn
}
