import axios from 'axios'

class WebapiError extends Error {
  constructor(message, status) {

    super(message)
    this.name = 'WebapiError'
    this.status = status

  }
}

const prefix = '/'

const webapi = {
  install(app) {
    app.config.globalProperties.$webapi = this
    app.provide('webapi', this)
  },
  handleError(error, url) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
      if (error.response.data.Message) {
        throw new WebapiError(error.response.data.Message, error.response.status)
      }
      if (error.response.data.message) {
        throw new WebapiError(error.response.data.message, error.response.status)
      }
      if (error.response.data.length > 100) {
        throw new WebapiError('Server returned error status ' + error.response.status, error.response.status)
      }
      throw new WebapiError(error.response.data, error.response.status)
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
      throw new WebapiError('No Response for ' + url, 0)
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
      throw new WebapiError('Error with settinmg up request', 0)
    }
  },
  async get(url) {
    try {
      const res = await axios.get(prefix + url)
      return res.data
    }
    catch (error) {
      this.handleError(error, url)
    }

  },
  async delete(url) {
    try {
      const res = await axios.delete(prefix + url)
      return res.data
    }
    catch (error) {
      this.handleError(error, url)
    }
  },
  async post(url, data) {
    try {
      const res = await axios.post(prefix + url, data)
      return res.data
    }
    catch (error) {
      this.handleError(error, url)
    }
  }
}

export default webapi